body,
html,
a {
    font-family: 'Ubuntu', sans-serif;
}
body {
  margin:0;
  padding:0;
  border: 0;
  outline: 0;
  background: #fff;
  overflow-x: hidden;
}
a:hover {
    color: #000;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Ubuntu', sans-serif;
    color: #0a1f44;
    font-size: 2.575rem;
    line-height: 3.0625rem;
}
p {
    color: #343D48;
    font-size: 1.125rem;
}
h1 {
    font-weight: 600;
}
a {
    text-decoration: none;
    outline: none;
    color: #2E186A;
}
a:hover {
  color: #2e186a;
}

*:focus {
    outline: none;
}
.about-block-image svg {
    text-align: center;
}
.ant-drawer-body {
    display: flex;
    flex-direction: column;
    padding: 1.25rem;
    text-align: left;
    padding-top: 2.5rem;
    padding-right: 2rem;
}
.main-container {
  position: relative;
  width: 100%;
  max-width: 1280px;
  margin-right: auto;
  margin-left: auto;
  padding: 0px 25px;
  overflow: hidden;
}
.ant-input {
  padding: 6px 12px;
  font-size: 18px;
  border-radius: 4px;
}
.ant-back-top {
  bottom: 90px;
}
.ant-btn {
  height: 46px;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 7px;
}
.ant-btn-primary {
  background: #48c1d4;
  border-color: #48c1d4;
}
.bg-shape-1 {
  position: absolute;
  width: 100vh;
  left: 0; 
  top: 50%;
  transform: translate(-50%, -50%);
}
.bg-shape-2 {
  position: absolute;
  width: 50vh;
  right: 0; 
  bottom: 35%;
  z-index: -1;
}
.bg-shape-3 {
  position: absolute;
  width: 50vh;
  left: 0; 
  bottom: 10%;
  z-index: -1;
}

@media only screen and (max-width: 480px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
      font-size: 1.8rem;
  }
}

/* Ipad Pro */
  /* Portrait */
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: 2rem;
    }
  }